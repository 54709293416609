import { IChatPersona } from "../models/ChatPersona";
import { BaseService } from "./BaseService";

export class PersonaService extends BaseService {
    public getAllPersonasAsync = async (accessToken: string): Promise<IChatPersona[]> => {
        const result = await this.getResponseAsync<IChatPersona[]>(
            {
                commandPath: 'chatPersonas',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getAllPersonasByUserAsync = async (accessToken: string): Promise<IChatPersona[]> => {
        const result = await this.getResponseAsync<IChatPersona[]>(
            {
                commandPath: `chatPersonasUser`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getChatPersonaAsync = async (personaId: string, accessToken: string): Promise<IChatPersona> => {
        const result = await this.getResponseAsync<IChatPersona>(
            {
                commandPath: `chatPersona/${personaId}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public createChatPersonaAsync = async (persona: IChatPersona, accessToken: string): Promise<IChatPersona> => {
        const result = await this.getResponseAsync<IChatPersona>(
            {
                commandPath: `chatPersona`,
                method: 'POST',
                body: persona,
            },
            accessToken,
        );
        return result;
    }
}
