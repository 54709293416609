import { PersonaService } from "../services/PersonaService";
import { AuthHelper } from '../auth/AuthHelper';
import { useMsal } from "@azure/msal-react";
import { IChatPersona } from "../models/ChatPersona";

export const usePersonas = () => {
    const personaService = new PersonaService();
    const { instance, inProgress } = useMsal();

    const getPersonasByUser = async () => {
        let personas : IChatPersona[] = [];
        try {
            personas = await personaService.getAllPersonasByUserAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        }
        catch (error) {
            // TODO: Add error handling
            console.error(error);
        }
        return personas;
    }

    const getPersonaById = async (personaId?: string) => {
        let persona : IChatPersona = {} as IChatPersona;
        persona.icon = 'bot-icon-1';
        persona.id = "00000000-0000-0000-0000-000000000000";
        persona.name = "Litium";

        if (!personaId) {
            return persona;
        }

        try {
            persona = await personaService.getChatPersonaAsync(personaId, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        }
        catch (error) {
            // TODO: Add error handling
            console.error(error);
            return persona;
        }
        return persona;
    }

    return {
        getPersonasByUser,
        getPersonaById
    }
}
