/* eslint-disable */
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { useChat } from '../../../libs/hooks';
import { useEffect, useState } from "react";


export interface LitiumDocViewerProps {
    chatId?: string;
    filename?: string;
    documentId?: string;
    mimeType?: string;
    page?: number;
}

export const LitiumDocViewer : React.FC<LitiumDocViewerProps> =({chatId, filename, documentId, mimeType, page}) => {
  const [docs, setDocs] = useState<IDocument[]>([]);

  //'{"documents":[{"name":"iOS_All_New_Features.pdf","size":"3.1MB","isUploaded":true,"documentId":"487d3438-0a89-4823-93a7-e07fd3072b54","mimeType":"application/pdf"}]}'

    chatId = chatId;
    filename = filename;
    documentId = documentId;
    mimeType = mimeType;
    page = page;

    chatId = "ee9c0eb0-5791-4b87-8177-e88f795bf8d1";
    filename = "iOS_All_New_Features.pdf";
    documentId = "487d3438-0a89-4823-93a7-e07fd3072b54";
    mimeType = "application/pdf";
    page = 1;

    const cid:string = chatId;
    const did:string = documentId;

    const chat = useChat();

    
    useEffect(() => {

      chat.getDocumentBytes(cid, did).then(
        (result)=>{
           if(result!=undefined && mimeType!=undefined){
            const fileBlobUrl = dataToBlob(result, mimeType);
            const newDocs:IDocument[] = [{uri : fileBlobUrl}];
            setDocs(newDocs);
          }
        });
      
    }, []);
    
    

    //dataToBlob(file);

    /*const docs = [
        { uri: "/pdf/twRespBus.pdf" }, // Remote file
      ];*/

      //function dataToBlob(dataURI) {
        function dataToBlob(inputBytes:string, mimeType:string) {
        var byteString = atob(inputBytes);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var bb = new Blob([ab], {type: mimeType});
        const blobUrl = URL.createObjectURL(bb);
        return blobUrl;
    }
    

    return (
        <div style={{margin:"0", padding:"0", backgroundColor:"#f5f5f5"}} >
        <div style={{height:"60px", margin:"0", padding:"0", backgroundColor:"var(--colorNeutralBackground4)", top:"0", borderBottom:"1.25px solid #0000001a"}}>&nbsp;</div>
        <div style={{backgroundColor:"var(--colorNeutralBackground4) !important",borderLeft:"1.25px solid #0000001a"}} >
        <DocViewer documents={docs} 
            pluginRenderers={DocViewerRenderers} 
            config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                },
                csvDelimiter: ",", // "," as default,
                pdfZoom: {
                  defaultZoom: 1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: false, // false as default
              }}
              style={{width:'500px', height:"80%"}}
        />
        </div>
        </div>
    );
};